import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import BlogDate from "../blog-date";
import ContentfulLink from "../../../elements/ContentfulLink";

interface BlogRelatedArticleProps {
  slug: string
  heading: string
  category: string
  date: string
  image: any
}

const BlogRelatedArticle: React.FC<BlogRelatedArticleProps> = ({ slug, date, image, heading, category }) => {
  return (
    <article className="card card-blog-summary card-blog-summary-grid">
      <div className="card-image">
        {image && (
          <GatsbyImage
            imgStyle={{ height: "82.5%" }}
            className="img-fluid"
            image={image.fluid}
            alt={image.title || ""}
          />
        )}
        <BlogDate>{date}</BlogDate>
      </div>
      <div className="card-body">
        <p className="card-subtitle">{category}</p>
        <h3 className="card-title">{heading}</h3>
        <ContentfulLink className="btn-link" link={{
          type: 'Page',
          page: { slug: slug },
          label: "Read More"
        }} />
      </div>
    </article>
  )
}

export default BlogRelatedArticle
