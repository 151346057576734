import React from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import "./style.scss"

interface BlogAuthorProps {
  name: string
  biography: string
  image: any
  title?: string
}

const BlogAuthor: React.FC<BlogAuthorProps> = ({ name, title, biography, image }) => {
  return (
    <div className="author">
      <span className="side-heading">Author Bio</span>
      <div className="row">
        <div className="col-lg-4 col-xl-3">
          {image && <GatsbyImage alt={image.title || ""} image={image.fluid} className="img-fluid" />}
        </div>
        <div className="col-lg-8">
          <header className="author-header">
            <h2 className="h3">{name}</h2>
            {title && <p>{title}</p>}
          </header>
          <p>{biography}</p>
        </div>
      </div>
    </div>
  )
}

export default BlogAuthor
