import React from "react"
import RichTextRenderer from "../../../elements/RichTextRenderer";
import "./style.scss"

interface IBlogContent {
  content: string
}

const BlogContent: React.FC<IBlogContent> = ({content}) => {
  return (
    <div className="cms-content">
      <RichTextRenderer json={content}/>
    </div>
  )
}

export default BlogContent
