import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import BlogPostLine from "../post-line"
import BlogDate from "../blog-date"

interface BlogHeaderProps {
  heading: string
  category: string
  date: string
  image: any
}

const BlogHeader: React.FC<BlogHeaderProps> = ({ heading, category, date, image }) => {
  return (
    <section className="featured-post blog-header">
      <div className="row flex-column-reverse justify-content-end">
        <div className="col-lg-12">
          <GatsbyImage
            className="img-fluid"
            image={image?.fluid}
            alt={image?.title || ""}
          />
        </div>
        <div className="container">
          <div className="col-lg-12 blog-header-content">
            <p className="h4">
              {category}
              <BlogDate>{date}</BlogDate>
            </p>
            <h1>
              <span>{heading}</span>
            </h1>
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlogHeader
