import React from "react"
import BlogRelatedArticle from "../related-article-single";

interface BlogRelatedArticlesProps {
  relatedBlogs: Array<any>
}

const BlogRelatedArticles: React.FC<BlogRelatedArticlesProps> = ({relatedBlogs}) => {
  return (
    <div className="related-blogs">
      {relatedBlogs && relatedBlogs.length > 0 && (
        <div className="container">
          <div className="text-center">
            <h2>Related Articles</h2>
          </div>
          <div className="row clear grid">
            {relatedBlogs.map(blog => (
              <div className="col-lg-4">
                <BlogRelatedArticle slug={blog.slug} heading={blog.heading} category={blog.category} date={blog.date}
                                    image={blog.image}/>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default BlogRelatedArticles
