import React from "react"
import { PageProps, graphql } from "gatsby"
import PathContext from "../contexts/path.context"
import Layout from "../layouts/Primary/layout"
import "../layouts/Primary/styles/skin/templates/_blog.scss"
import BlogAuthor from "../components/blogs/author"
import BlogRelatedArticles from "../components/blogs/related-article"
import BlogPostSocials from "../components/socials"
import BlogHeader from "../components/blogs/header"
import BlogContent from "../components/blogs/content"
import LinkedinNoscript from "./noscript/noscript-linkedin"
import FacebookNoscript from "./noscript/noscript-facebook"
import GTMNoscript from "./noscript/noscript-gtm"

export interface IBlogDetailFieldsProps {
  title: string
  seoTitle: string
  seoDescription: string
  id: string
  category: string
  heading: string
  content: string
  date: string
  image: any
  featuredImage: any
  blogSinglePageImage: any
}

export interface IBlogDetailProps extends IBlogDetailFieldsProps {
  author: {
    name: string
    title: string
    biography: {
      biography: string
    }
    image: any
  }
  relatedArticles: Array<IBlogDetailFieldsProps>
}

export interface IBlogDetail {
  contentfulCareersBlogPost: IBlogDetailProps
}

interface ICareersBlogDetailContext {
  id: string
  slug: string
  locale: string
}

const BlogDetail: React.FC<PageProps<IBlogDetail, ICareersBlogDetailContext>> = ({ pageContext, path, data }) => {
  const locale = pageContext.locale || "en-US"
  const seoData = {
    title: `Guidewire Careers Blog - ${
      data.contentfulCareersBlogPost.seoTitle || data.contentfulCareersBlogPost.title
    }`,
    ogTitle: `Guidewire Careers Blog - ${
      data.contentfulCareersBlogPost.seoTitle || data.contentfulCareersBlogPost.title
    }`,
    description: data.contentfulCareersBlogPost.seoDescription,
    image: "http:" + data.contentfulCareersBlogPost?.featuredImage?.fluid?.src,
  }
  const BlogPostSocialsComponent = <BlogPostSocials title={data.contentfulCareersBlogPost.heading} />
  return (
    <PathContext.Provider
      value={{
        current: path,
        slug: pageContext.slug,
        locale: locale,
        activeLanguage: locale.substring(0, 2),
      }}
    >
      {/* Layout contains menus */}

      <Layout overflowEnable seoData={seoData} dispatch={() => 0} className='single-blog-page-container'>
        <BlogHeader
          heading={data.contentfulCareersBlogPost.heading}
          category={data.contentfulCareersBlogPost.category}
          date={data.contentfulCareersBlogPost.date}
          image={data.contentfulCareersBlogPost.blogSinglePageImage}
        />

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 blog-detail">
              <div className="share mt-0">
                <p>Share this article</p>
                {BlogPostSocialsComponent}
              </div>

              <BlogContent content={data.contentfulCareersBlogPost.content} />

              <div className="share justify-content-center justify-content-md-start">
                <p>Share this article</p>
                {BlogPostSocialsComponent}
              </div>

              {data.contentfulCareersBlogPost.author && (
                <BlogAuthor
                  name={data.contentfulCareersBlogPost.author.name}
                  biography={
                    data.contentfulCareersBlogPost.author.biography &&
                    data.contentfulCareersBlogPost.author.biography.biography
                  }
                  title={data.contentfulCareersBlogPost.author.title}
                  image={data.contentfulCareersBlogPost.author.image}
                />
              )}
            </div>
          </div>
        </div>

        <BlogRelatedArticles relatedBlogs={data.contentfulCareersBlogPost.relatedArticles} />
      </Layout>

      {/* Linkedin, Facebook and GTM noscript */}
      <LinkedinNoscript />
      <FacebookNoscript />
      <GTMNoscript />
    </PathContext.Provider>
  )
}

export default BlogDetail

export const query = graphql`
  query ($id: String!) {
    contentfulCareersBlogPost(id: { eq: $id }) {
      title
      seoTitle
      seoDescription
      ...BlogPost
    }
  }
`
